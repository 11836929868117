import React from "react"

//import { Link } from "gatsby"
import Layout from "../components/layout"
import LogoImage from "../components/logoimage"
import Seo from "../components/seo"
import Footer from "../components/footer"
import {Hero, Container, Heading, Navbar, Content} from 'react-bulma-components'
const PrivacyPolicyPage = () => {
  
  return (  
      <Layout>
          <Seo title="Greenheat Privacy Policy" 
          description="This Privacy Policy document contains types of information that is collected and recorded by Greenheat systems and how we use it"
          />
          <Hero size="fullheight">
            <Hero.Header renderAs="header">
                <Container>
                <Navbar>
                <Navbar.Brand>
                    <Navbar.Item href='/'>
                    <LogoImage type="green"/>
                    </Navbar.Item>
                </Navbar.Brand>
                </Navbar>
                </Container>
            </Hero.Header>
            <Hero.Body>
          <Container>
        <Heading>Privacy Policy for Greenheat Sp. z o.o.</Heading>
        <Content>At Greenheat systems, accessible from https://greenheat.systems, one of our main priorities is the privacy of our visitors. 
            This Privacy Policy document contains types of information that is collected and recorded by Greenheat systems and how we use it.</Content>     
        <Heading renderAs="h2">Log Files</Heading>
        <Content>Greenheat systems follows a standard procedure of using log files. These files log visitors when they visit websites. 
            All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet 
            protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the 
            number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing 
            trends, administering the site, tracking users' movement on the website, and gathering demographic information.
            </Content>
        <Heading renderAs="h2">Cookies and Web Beacons</Heading>
        <Content>Like any other website, Greenheat systems uses 'cookies'. These cookies are used to store information including visitors' 
            preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' 
            experience by customizing our web page content based on visitors' browser type and/or other information.</Content>

        <Heading renderAs="h2">Privacy Policies</Heading>
        <Content>
            You may consult this list to find the Privacy Policy for each of the advertising partners of Greenheat systems. 
        </Content>
        <Content>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their 
            respective advertisements and links that appear on Greenheat systems, which are sent directly to users' browser. 
            They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their 
            advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
        </Content>
        <Content>Note that Greenheat systems has no access to or control over these cookies that are used by third-party advertisers.</Content>

        <Heading renderAs="h2">Third Party Privacy Policies</Heading>
        <Content>Greenheat systems's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</Content>
        <Content>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</Content>

        <Heading renderAs="h2">Children's Information</Heading>
        <Content>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</Content>
        <Content>Greenheat systems does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</Content>

        <Heading renderAs="h2">Online Privacy Policy Only</Heading>
        <Content>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Greenheat systems. This policy is not applicable to any information collected offline or via channels other than this website.</Content>

        <Heading renderAs="h2">Consent</Heading>
        <Content>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</Content>
        </Container>
        </Hero.Body>
        </Hero>

        <Footer/>
    </Layout>
  )
  
}

export default PrivacyPolicyPage